html,
body {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-height: 100vh;
  width: 100%;
  margin: 0;
  background-color: #eeeff1;
  font-family: "Poppins", "-apple-system", "BlinkMacSystemFont", "Segoe UI",
    "Roboto", "Helvetica Neue", "Arial", "sans-serif", "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
}

/* Shared colors across brands */
.error,
.failedValidation {
  color: #f44336;
}
.success,
.successfulValidation {
  color: green;
}
.negativeAmount {
  color: #a20000 !important;
}
.validationHelper {
  background-image: url(./svg/circleMark.svg);
  background-repeat: no-repeat;
  background-size: 1.5em;
  background-position: left center;
  padding-left: 2em;
  transition: 0.3s all ease-in-out;
}
.failedValidation {
  background-image: url(./svg/xMark.svg);
}
.successfulValidation {
  background-image: url(./svg/checkMark.svg);
}

/* Sizing and layout updates for Material UI to work properly with our layout */
#root {
  height: 100%;
}

#appHeader .loading {
  min-height: 50px;
}

.Mui-disabled,
.Mui-disabled .MuiInputBase-root {
  opacity: 0.4;
}

/* svgs */
.st0 {
  opacity: 1 !important;
}

#userIcon .st0 {
  fill: #1373e6 !important;
  stroke: #1373e6 !important;
}

/* IE11 Hacks */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  #appHeader {
    position: static;
    height: 60px;
  }
  #logoContainer {
    height: 40px;
    padding: 10px;
  }
}
